/**
 * Page Node template.
 *
 * Each "page" node in Drupal that has the destination set to "Inside Sandy Springs"
 * will use this template to generate a corresponding page with Gatsby.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import InteriorLayout from '../components/interior-layout';
import WYSIWYG from '../components/wysiwyg';
import styles from '../components/interior-layout/interior-layout.module.scss';

const BasicPageNode = ({ data }) => {
  return (
    <Layout>
      <InteriorLayout>
        <div className={styles.middleContent} to="/">
          <h1 className={styles.pageTitle}>{data?.nodePage?.title}</h1>
          <WYSIWYG body={data?.nodePage?.body?.processed} />
        </div>
      </InteriorLayout>
    </Layout>
  );
};

BasicPageNode.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query BasicPageNodeQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    nodePage(path: { alias: { eq: $slug } }) {
      title
      drupal_id
      path {
        alias
      }
      body {
        processed
        summary
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
      field_image {
        alt
      }
    }
  }
`;

export default BasicPageNode;
